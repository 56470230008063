
















import Vue from 'vue';
import Component from 'vue-class-component';
import JwlButton from '@/components/JwlButton.vue';
import SortableTable from '@/components/SortableTable.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    SortableTable,
    CommonError,
    JwlButton,
  },
})
export default class JwlStudent extends Vue {
}
