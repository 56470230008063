












































































































import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import Component from 'vue-class-component';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    CommonError,
    CommonIcon,
  },
})
export default class SortableTable extends Vue {
  $refs!: {
    tableContainer: HTMLElement;
  }

  @Prop(String)
  apiUrl!: string;

  @Prop(String)
  linkName!: string;

  @Prop(String)
  linkParam!: string;

  @Prop(String)
  linkParamName!: string;

  syncStatus = 1;
  sumResults = 0;
  resultsFrom = 0;
  resultsTo = 0;
  error = null;
  tableData = [];
  tableHead = [];
  sortBy = '';
  sortOrder = 'asc';
  currentPage = 1;
  searchTerm = '';
  perPage = 50;
  initialLoaded = false;
  toPage = '';
  toPageTimer: number | null = null;

  loadData (): void {
    this.syncStatus = 1;

    const formData = new FormData();
    formData.set('sortBy', this.sortBy);
    formData.set('sortOrder', this.sortOrder);
    formData.set('page', this.currentPage.toString());
    formData.set('search', this.searchTerm);

    this.$store.dispatch('postData', {
      url: this.apiUrl,
      formData,
    }).then((data) => {
      this.tableData = data.list;
      this.sumResults = data.sum;
      this.resultsFrom = data.resultsFrom;
      this.resultsTo = data.resultsTo;
      this.error = null;
      this.syncStatus = 2;
    }).catch((e) => {
      this.error = e;
    });
  }

  sortTable (sortBy: string, sortOrder: string): void {
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.currentPage = 1;
    this.loadData();
  }

  tableSortClass (sortBy: string, sortOrder: string): Record<string, boolean> {
    return {
      'sortable-table__sort-button--active': this.sortBy === sortBy && this.sortOrder === sortOrder,
    };
  }

  activeNumberClass (pageNumber: number | string): Record<string, boolean> {
    return {
      'sortable-table__pagination-step--active': this.currentPage === Number(pageNumber),
    };
  }

  linkObject (data: any): Record<string, string|Record<string, string>> {
    const linkParamName = this.linkParamName || this.linkParam;
    return {
      name: this.linkName,
      params: {
        [linkParamName]: data[this.linkParam],
      },
    };
  }

  setPage (page: number | string): void {
    this.currentPage = Number(page);
    if (this.$refs.tableContainer) {
      window.scrollTo({
        behavior: 'smooth',
        top: this.$refs.tableContainer.offsetTop - 200,
      });
    }
    this.loadData();
  }

  formattedNumber (number: number): string {
    return number.toLocaleString();
  }

  // Handles pagination form input
  @Watch('toPage')
  handlePageChange (newPage: string, oldPage: string): void {
    if (oldPage !== newPage && newPage !== '') {
      if (this.toPageTimer !== null) {
        window.clearTimeout(this.toPageTimer);
      }
      this.toPageTimer = window.setTimeout(() => {
        this.toPageTimer = null;
        this.setPage(newPage);
        this.toPage = '';
      }, 500);
    }
  }

  @Watch('searchTerm')
  handleSearchChange (newValue: string): void {
    this.syncStatus = 1;
    setTimeout(() => {
      if (newValue === this.searchTerm) {
        this.currentPage = 1;
        this.loadData();
      }
    }, 1250);
  }

  get lastPage (): number {
    return Math.ceil(this.sumResults / this.perPage);
  }

  get pages (): Array<string | number> {
    const countPages = this.lastPage;
    const returnArray: Array<string | number> = [];
    const current = this.currentPage;
    let earliestPage;
    let latestPage;
    returnArray.push(1);
    if (current <= 7 || countPages <= 10) {
      earliestPage = 2;
    } else {
      returnArray.push('...');
      earliestPage = current - 3;
    }
    if (current <= 7 && countPages > 10) {
      latestPage = 7;
    } else if (countPages - current <= 7 || countPages <= 10) {
      latestPage = countPages - 1;
    } else {
      latestPage = current + 3;
    }
    for (let i = earliestPage; i <= latestPage; i += 1) {
      returnArray.push(i);
    }
    if (latestPage !== countPages - 1) {
      returnArray.push('...');
    }
    returnArray.push(this.lastPage);
    return returnArray;
  }

  get pageBeforeClass (): Record<string, boolean> {
    return {
      'sortable-table__pagination-arrow--disabled': this.currentPage <= 1,
    };
  }

  get pageBefore (): number {
    return Math.max(this.currentPage - 1, 1);
  }

  get pageAfterClass (): Record<string, boolean> {
    return {
      'sortable-table__pagination-arrow--disabled': this.currentPage >= this.lastPage,
    };
  }

  get pageAfter (): number {
    return Math.min(this.currentPage + 1, Number(this.lastPage));
  }

  mounted (): void {
    this.$store.dispatch('getData', this.apiUrl)
      .then((data) => {
        this.tableData = data.list;
        this.tableHead = data.tableHead;
        this.sumResults = data.sum;
        this.perPage = data.perPage;
        this.resultsFrom = data.resultsFrom;
        this.resultsTo = data.resultsTo;
        this.sortBy = data.sortBy;
        this.syncStatus = 2;
        this.initialLoaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
